@import '../../assets/styles/_globals.scss';

.menu__desktop {
  li {
    display: inline;
    margin: 20px;
  }
  li a {
    text-decoration: none;
    color: white;
    padding-bottom: 5px;
    border-bottom: 3px solid transparent;
    &.active,
    &:hover {
      border-bottom: 3px solid $yellow;
    }
    transition: border-bottom 0.2s;
  }
}
