@import '../../assets/styles/_globals.scss';

.footer {
  grid-area: footer;
  background-color: $dark_blue;
  text-align: center;
  padding: 1em;
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    display: inline;
  }
  svg {
    path {
      fill: white;
    }
    &:hover {
      transition: transform 0.2s ease-in-out;
      transform: scale(1.3);
    }
  }
  .footer__email {
    margin-bottom: 1em;
    color: white;
    font-weight: bolder;
  }
}
