@import '../../assets/styles/_globals.scss';
.project {
  display: grid;
  grid-template-areas:
    'title'
    'desc'
    'techUsed'
    'buttons'
    'hr';
  grid-template-rows: max-content max-content 1fr max-content 0.2fr;
  grid-template-columns: 1fr;
  grid-row-gap: 1em;
}
.project__title {
  grid-area: title;
  text-align: center;
  justify-self: center;
  font-family: $julius;
  font-size: 2em;
  color: #161855;
}
.project__image {
  display: none;
}
.project__desc {
  grid-area: desc;
  text-align: center;
  p {
    margin-top: 0;
  }
}
.project__tech-used {
  grid-area: techUsed;
  align-self: start;
  justify-self: center;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  ul li::before {
    content: '\25A0';
    color: dodgerblue;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}
.project__links {
  grid-area: buttons;
  display: grid;
  grid-template-areas: 'button1 button2';
  grid-template-columns: 1fr 1fr;
}
.hr {
  grid-area: hr;
  hr {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), $dark_blue, rgba(0, 0, 0, 0));
  }
}
.project__link {
  width: 100%;
  border: none;
  border-radius: 4px;
  color: white;
  padding: 1em;
  text-align: center;
  text-decoration: none;
  font-size: 1em;
  margin: 4px 2px;
}
.project__link--github {
  grid-area: button1;
  justify-self: end;
  background-color: DodgerBlue;
}

.project__link--hosted {
  grid-area: button2;
  justify-self: start;
  background-color: DodgerBlue;
}
.project__link--github {
  grid-area: button1;
  justify-self: end;
  background-color: DodgerBlue;
}

.project__link--hosted {
  grid-area: button2;
  justify-self: start;
  background-color: DodgerBlue;
}

@media only screen and (min-width: 600px) {
  .project {
    display: grid;
    grid-template-areas:
      'img title title'
      'img desc techUsed'
      'img buttons .'
      'hr hr hr';
    grid-template-rows: 100px 1fr 60px 10px;
    grid-template-columns: 40% 2fr 1fr;
    margin-bottom: 2em;
  }
  .project__image {
    display: block;
    padding: 0 2em;
    grid-area: img;
    align-self: center;
    img {
      border: 1px solid $dark_grey;
      height: auto;
      width: 100%;
    }
  }
  .project__title {
    grid-area: title;
    align-self: center;
    justify-self: center;
    font-family: $julius;
    font-size: 2em;
    color: #161855;
  }
  .project__desc {
    grid-area: desc;
    align-self: start;
    justify-self: center;
    text-align: left;
  }
  .project__link {
    cursor: pointer;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    &:hover {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
  }
  .hr {
    grid-area: hr;
  }
  .project__tech-used {
    justify-self: end;
  }
}
