@import '../../assets/styles/_globals.scss';
.splash__text {
  font-size: 12px;
  color: grey;
  font-style: italic;
  padding-left: 1em;
}
.splash__item {
  margin-bottom: 1em;
  padding-left: 3em;
  span {
    font-family: $julius;
    color: $dark_blue;
    font-size: 3em;
    font-style: normal;
    display: block;
  }
}
.splash__braces {
  font-family: $julius;
  color: $dark_blue;
  font-style: italic;
  font-size: 8em;
  font-weight: bolder;
}

@media only screen and (min-width: 600px) {
  .splash__text {
    font-size: 1em;
    margin-top: 0;
  }
  .splash__item {
    margin-bottom: 0;
    span {
      font-size: 5em;
      display: inline;
    }
  }
  .splash__braces {
    font-size: 7em;
  }
}
