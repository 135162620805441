@import '../../assets/styles/_globals.scss';

.app {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  grid-template-areas:
    'header'
    'main'
    'footer';
}
