@import '../../assets/styles/_globals.scss';

.header {
  padding: 0 20px;
  background-color: $dark_blue;
  color: white;
}
.header__container {
  display: grid;
  grid-area: header;
  align-items: center;
  grid-template-areas: 'header1 header2';
  margin-left: auto;
  margin-right: auto;
}

.logo {
  grid-area: header1;
  justify-self: start;
  font: {
    family: $yellowtail;
    size: 3em;
  }
  a {
    text-decoration: none;
    color: white;
  }
}

.menu {
  display: none;
}
.menu__mobile {
  grid-area: header2;
  align-self: center;
  justify-self: end;
}

.logo__accent {
  font-family: $julius;
  font-style: italic;
  letter-spacing: -5px;
  color: $yellow;
}

//If device width is greater than or equal to 600px, then do:
@media only screen and (min-width: 600px) {
  .menu {
    grid-area: header2;
    justify-self: end;
    display: block;
  }
  .header {
    margin-bottom: 1em;
  }
  .header__container {
    max-width: 1025px;
  }
  .menu__mobile {
    display: none;
  }
}
