@import '../../assets/styles/_globals.scss';

.about {
  span {
    font-family: $julius;
    font-size: 2em;
    color: $dark_blue;
  }
  ul {
    list-style: none;
  }
  ul li::before {
    content: '\25A0';
    color: dodgerblue;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}

.about_container {
  padding: 1em;
  display: grid;
  grid-template-areas:
    'blurb1 '
    'blurb2'
    'toolbox'
    'misc';
  grid-row-gap: 2em;
  width: 100%;
  height: auto;
  span {
    display: table;
    margin: 0 auto;
  }
}

.about_blurb--image {
  width: 100%;
  grid-area: blurb1;
  justify-self: center;
  align-self: center;
}
.about_blurb--text {
  grid-area: blurb2;
  justify-self: start;
  align-self: center;
}
.about_tools {
  width: 100%;
  grid-area: toolbox;
  justify-self: start;
  align-self: center;
}

.about_misc {
  width: 100%;
  grid-area: misc;
  justify-self: start;
  align-self: center;
}

@media only screen and (min-width: 600px) {
  .about_container {
    grid-template-areas:
      'blurb1 blurb2'
      'toolbox misc';
    span {
      display: block;
    }
  }
  .about_blurb--image {
    width: 80%;
    height: auto;
    grid-area: blurb1;
    justify-self: start;
    align-self: center;
  }
  .about_blurb--text {
    grid-area: blurb2;
    justify-self: center;
    align-self: center;
  }
  .about_tools {
    grid-area: toolbox;
    justify-self: end;
    align-self: start;
  }

  .about_misc {
    grid-area: misc;
    justify-self: start;
    align-self: start;
  }
}
