@import '../../assets/styles/_globals.scss';

.main {
  grid-area: main;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
}
@media only screen and (min-width: 600px) {
  .main {
    max-width: 1025px;
  }
}
